<template>
    <div class="modal-popup">
        <div class="mb-3 modal-popup__title">
            Перенос конфига между типами
            
            <svg
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2D4C70"
                class="bi bi-x-lg modal-popup__close"
                viewBox="0 0 16 16"
                @click="close"
            >
                <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                />
            </svg>
        </div>
        <div class="form-group">
            <label>Исходный конфиг:</label>
            <b-form-select v-model="sourceIndex" :options="environments" />
        </div>
        <div class="form-group">
            <label>Копируем в конфиг:</label>
            <b-form-select v-model="destinationIndex" :options="environments" />
        </div>
        <ui-button @clicked="onCopyClick">Копировать</ui-button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        sourceIndex: 0,
        destinationIndex: 0,
        environments: [],
        versions: []
    }),
    components: {
        UiButton: () => import('../ui/UiButton')
    },
    created() {
        this.environments = this.$store.getters['firebase/getConfigEnvs'].map((envName, index) => {
            return { value: index, text: envName };
        });
    },
    watch: {
        //todo надо понять как копировать конфиги
    },
    methods: {
        ...mapActions('firebase', ['copyConfigs']),
        close() {
            this.$modal.hide('copy-config-modal');
        },
        async onCopyClick() {
            if (this.sourceIndex === this.destinationIndex) {
                alert('Конфиги должны отличаться');
                return;
            }
            
            let sourceName = this.environments.find(opt => opt.value === this.sourceIndex).text;
            let destinationName = this.environments.find(opt => opt.value === this.destinationIndex).text;
            
            sourceName = sourceName === 'Default' ? '' : `_${sourceName}`;
            destinationName = destinationName === 'Default' ? '' : `_${destinationName}`;
            
            const response = await this.copyConfigs({ sourceName, destinationName });
            
            if (!response.error) {
                bus.$emit('show-notification-message', 'Конфиг успешно скопирован');
                this.close();
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
            }
        }
    }
};
</script>
