<template>
    <div class="nav-bar-top">
        <div class="nav-bar-top__logged-block" :class="{'nav-bar-top__logged-block--hidden': !accessToken}">
            <div class="nav-bar-top__menu" @click="onMenuClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#2D4C70" class="bi bi-list"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </div>
        </div>
        <div class="container nav-bar-top__logo-wrapper">
            <div class="nav-bar-top__logo" @click="onLogoClick">NOVEL ADMIN</div>
        </div>
        <config-type-picker/>
        <svg v-if="isInitialized" @click="onCopyConfigButtonClick"
             class="nav-bar-top__copy-config-button" v-b-tooltip.hover title="Скопировать содержимое конфига"
             xmlns="http://www.w3.org/2000/svg" width="24"
             height="24" viewBox="0 0 24 24">
            <path fill="#2d4c70"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"/>
        </svg>
        <div v-if="accessToken" class="nav-bar-top__login">
            <div class="nav-bar-top__login-name">{{ userEmail || 'Вход' }}</div>
            <div class="nav-bar-top__logout-button" @click="onSignOutClick"/>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import bus from '@/plugins/bus';
import {openCopyConfigModal} from '@/modals/copyConfigModal';

export default {
    computed: {
        ...mapState('firebase', ['userEmail', 'accessToken', 'isInitialized'])
    },
    components: {
        ConfigTypePicker: () => import('./ConfigTypePicker')
    },
    methods: {
        ...mapActions('firebase', ['signOut']),
        onLogoClick() {
            this.$router.push('/');
        },
        async onSignOutClick() {
            await this.signOut();
            await this.$router.push('/');
        },
        onMenuClick() {
            bus.$emit('menu-clicked');
        },
        onCopyConfigButtonClick() {
            openCopyConfigModal();
        }
    }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.nav-bar-top {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 64px;
    background: #DDEDFF;
    position: relative;

    &__logged-block {
        position: absolute;
        top: 0;
        left: 0;

        &--hidden {
            visibility: hidden;
        }
    }

    &__menu {
        width: 72px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__logo-wrapper {
        position: absolute;
        transform: translateX(-50%);
        margin-left: 50%;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #2D4C70;
        font-size: 18px;
        font-weight: 700;
        width: 200px;
        height: 64px;
        cursor: pointer;
    }

    &__login {
        position: absolute;
        right: 56px;
        height: 100%;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__login-name {
        color: #4F6D90;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        text-align: left;
    }

    &__logout-button {
        cursor: pointer;
        background: url(../../assets/svg/ic_logout.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
        margin-left: 16px;
    }

    &__copy-config-button {
        position: absolute;
        top: 20px;
        right: 20vw;
        cursor: pointer;

        svg {
            fill: #2d4c70;
        }
    }
}
</style>
